<template>
<el-col class="statistical-item column-start-center">
  <div class="si-top row-center-between">
    <div class="sit-left">
      <span>{{data.value}}</span>
      <span>{{data.end}}</span>
    </div>
    <!-- <div class="sit-right">统计图表</div> -->
  </div>
  <div class="si-bottom">{{data.zn}}（{{data.eg}}）</div>
</el-col>
</template>

<script>
// 我的合伙人 - 顶部统计部分 - 组件
export default {
  props: {
    data: { type: Object }
  }
}
</script>

<style lang="scss" scoped>
.statistical-item {
  padding: 10px 10px;
  margin-right: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);

  &:last-child {
    margin-right: 0;
  }

  .si-top {
    width: 100%;
    margin-bottom: 10px;

    .sit-left {
      span {
        &:first-child {
          font-size: 36px;
        }
      }
    }
  }
}
</style>
