<template>
<el-dialog v-model="visible" title="选择合同模板" :before-close="close" :close-on-click-modal="false">
  <table class="my-table" v-loading="loading">
    <tr>
      <th></th>
      <th>模板ID</th>
      <th>设备类型</th>
      <th>模板名称</th>
    </tr>
    <tr v-for="(item, index) in list" :key="index">
      <td>
        <el-checkbox v-model="item.checked" :disabled="item.disabled" @change="checkboxChange(index)" />
      </td>
      <td>{{index+1}}</td>
      <td>{{item.txt_type_code}}</td>
      <td>{{item.txt_comments}}</td>
    </tr>
  </table>
  <div class="my-error" v-if="errorShow">请至少选择一个模板</div>
  <template #footer>
    <div class="dialog-footer">
      <el-button type="primary" @click="preview">预 览</el-button>
      <el-button @click="close" type="info">关 闭</el-button>
    </div>
  </template>
  <preview v-if="previewShow" :previewData="previewData" @close="()=>{previewShow=false;previewData=new Object();}" />
</el-dialog>
</template>

<script>
// 选择合同模板
import { toRefs, reactive, onMounted } from 'vue';
import { getContractModals } from "api/apis.js";
import Preview from "../Preview/index.vue";
export default {
  components: {
    Preview,
  },
  props: {
    chooseData: { type: Object },
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      list: [],
      loading: false,
      errorShow: false,
      previewShow: false,
      previewData: {}
    });
    const close = () => {
      ctx.emit("close");
    }

    onMounted(() => {
      getContractList();
    })

    // checkbox 选择事件
    const checkboxChange = index => {
      if (state.list[index].checked) {
        for (let i = 0; i < state.list.length; i++) {
          if (i != index) {
            state.list[i].disabled = true;
          } else {
            state.list[i].disabled = false;
          }
        }
      } else {
        for (let i = 0; i < state.list.length; i++) {
          state.list[i].disabled = false;
        }
      }
    }

    // 获取合同模板列表
    const getContractList = () => {
      state.loading = true;
      getContractModals({int_agent_level: prop.chooseData.int_agent_level}).then(response => {
        if (response.code == 200) {
          state.list = response.data.map(t => {
            // 遍历返回值，添加checkbox和禁用条件
            return {
              checked: false,
              disabled: false,
              t_contract_template_id: t.t_contract_template_id,
              txt_comments: t.txt_comments,
              txt_template_no: t.txt_template_no,
              txt_type_code: t.txt_type_code
            }
          });
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    // 预览合同
    const preview = () => {
      if (state.list.findIndex(t => t.checked) == -1) {
        state.errorShow = true;
      } else {
        state.errorShow = false;
        state.previewData = {
          agentid: prop.chooseData.t_agent_id,
          txt_contract_id: state.list.filter(t => t.checked)[0].t_contract_template_id,
          templateid: state.list.filter(t => t.checked)[0].txt_template_no
        }
        state.previewShow = true;
      }
    }

    return {
      ...toRefs(state),
      close,
      getContractList,
      preview,
      checkboxChange
    }
  }
}
</script>

<style lang="scss" scoped>
.my-error {
  color: red;
}
</style>
