<template>
<el-dialog v-model="visible" :title="title+' 审核'" :before-close="close">
  <div class="dialog-body partner-audit-dialog" v-loading="loading">
    <div class="pad-title">基本信息</div>
    <table class="pad-base-info-table my-table">
      <tr>
        <th>姓名</th>
        <td>{{compData.txt_realname}}</td>
        <th>预留手机号</th>
        <td>{{compData.txt_mobile}}</td>
      </tr>
      <tr>
        <th>申请地区</th>
        <td>{{compData.txt_area_name}}</td>
        <th>申请日期</th>
        <td>{{compData.dt_apply}}</td>
      </tr>
      <tr>
        <th>身份证号</th>
        <td colspan="3">{{compData.identityCardNo}}</td>
      </tr>
      <tr>
        <th>银行卡号</th>
        <td colspan="3">{{compData.bankCardNo}}</td>
      </tr>
      <tr>
        <th>开户行信息</th>
        <td colspan="3">{{compData.bankName}}</td>
      </tr>
      <tr>
        <th>微信登录地址</th>
        <td colspan="3">{{compData.txt_wechat_address}}</td>
      </tr>
      <tr>
        <th>微信登录坐标</th>
        <td colspan="3">{{compData.txt_wechat_coordinate}}</td>
      </tr>
      <tr v-if="compData.level == 1 || compData.level == 2">
        <th>钉钉审批号</th>
        <td colspan="3"><el-input v-model="txt_approval_number"></el-input></td>
      </tr>
      <template v-if="compData.level == 4">
        <tr>
          <th>诊所名称</th>
          <td colspan="3">{{compData.txt_clinic_name}}</td>
        </tr>
        <tr>
          <th>诊所地址</th>
          <td colspan="3">{{compData.txt_address}}</td>
        </tr>
        <tr class="pics-part-title">
          <th colspan="4">资质图片</th>
        </tr>
        <template v-if="picsData.length!=0">
          <tr v-for="(item, index) in picsData" :key="index" class="tr-has-image">
            <template v-if="item.txt_bucket_name.includes('identitycard-principal')">
              <th>{{item.txt_qualification_name}} - {{item.txt_image_params=='face'?'正面':'反面'}}</th>
            </template>
            <template v-else>
              <th>{{item.txt_qualification_name}}</th>
            </template>
            <td colspan="3">
              <el-image :src="item.txt_image_url" :preview-src-list="[item.txt_image_url]" :initial-index="4" fit="cover" />
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="4">暂无</td>
          </tr>
        </template>
      </template>
    </table>
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="audit(true)" type="primary">同 意</el-button>
      <el-button @click="audit(false)" type="info">拒 绝</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from "vue";
import { getAudit, getClinicQualification, getAgentDetail } from "api/apis.js";
import { ElMessage } from 'element-plus/lib/components';
import { useStore } from 'vuex';
export default {
  props: {
    auditData: { type: Object },
  },
  setup(prop, ctx) {
    const store = useStore();
    const state = reactive({
      visible: true,
      picsData: [],
      loading: false,
      txt_approval_number: null,
      compData: {},
      title: computed(() => {
        // 判断当前数据的合伙人级别，展示不同文字
        if (prop.auditData.level == 1) {
          return "省级合伙人";
        } else if (prop.auditData.level == 2) {
          return "市级合伙人";
        } else if (prop.auditData.level == 3) {
          return "市场专员";
        } else {
          return "诊所负责人";
        }
      })
    })

    onMounted(() => {
      getAuditDetail();
      if (prop.auditData.level == 4) {
        getPics();
      }
    })

    // 获取详情
    const getAuditDetail = () => {
      state.loading = true;
      getAgentDetail({
        agent_id: prop.auditData.t_agent_id
      }).then(response => {
        if (response.code == 200) {
          state.compData = response.data;
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.loading = false;
      })
    };

    // 审核
    const audit = param => {
      state.loading = true;
      getAudit({
        t_agent_id: prop.auditData.t_agent_id,
        txt_verify_status: param ? 1 : 2,
        txt_updater: store.getters.userInfo.name || "系统用户",
        txt_verify_userid: store.getters.userInfo.userid || "系统用户",
        txt_approval_number: state.txt_approval_number
      }).then(response => {
        if (response.code == 200) {
          ElMessage.success("审核成功");
          close();
        } else {
          ElMessage.success("审核失败" + response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    // 获取诊所资质图片
    const getPics = () => {
      getClinicQualification({
        t_agent_id: prop.auditData.t_agent_id
      }).then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.picsData = response.data;
          } else {
            state.picsData = [];
          }
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      })
    };

    const close = () => {
      ctx.emit("close");
    }

    return {
      ...toRefs(state),
      close,
      audit,
      getPics,
      getAuditDetail
    }
  }
}
</script>

<style lang="scss" scoped>
.partner-audit-dialog {
  .pad-title {
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0;
    margin-top: 0;
  }

  .pad-base-info-table {
    .pics-part-title {
      height: 50px;
      font-size: 18px;
    }

    .tr-has-image {
      .el-image {
        height: 200px;
      }
    }
  }
}
</style>
