<template>
<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" class="comp-pagination" v-model:currentPage="pagination.currentPage" v-model:page-size="pagination.pageSize" :page-sizes="[10, 50, 100, 200]" :total="total" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
</template>

<script>
// 分页组件
import { reactive, toRefs } from 'vue';
export default {
  props: {
    total: { type: Number },
  },
  setup(prop, ctx) {
    const state = reactive({
      pagination: {
        currentPage: 1,
        pageSize: 10,
      }
    });

    // 条数 更改事件
    const handleSizeChange = (number) => {
      ctx.emit("sizeChange", number)
    };

    // 页码变更事件
    const handleCurrentChange = (number) => {
      ctx.emit("pageChange", number)
    }

    return {
      ...toRefs(state),
      handleSizeChange,
      handleCurrentChange
    };
  }
}
</script>

<style lang="scss" scoped>
.comp-pagination {
  float: right;
}
</style>
