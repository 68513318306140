<template>
<el-dialog custom-class="view-contract-dialog" v-model="visible" title="预览合同" width="80%" :before-close="close" :close-on-click-modal="false">
  <div v-loading="dialogLoading" class="my-iframe">
    <iframe :src="url" scrolling="auto" frameborder="1" />
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="send" type="primary" :disabled="isDisabled">发 送</el-button>
      <el-button @click="close" type="info">返 回</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 预览合同
import { onMounted, reactive, toRefs } from 'vue';
import { previewTemplateContract, sendContract } from "api/apis.js";
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
export default {
  props: {
    previewData: { type: Object },
  },
  setup(prop, ctx) {
    const store = useStore();
    let moment = require("moment");
    const state = reactive({
      visible: true,
      dialogLoading: false,
      url: "",
      isDisabled: false
    });

    onMounted(() => {
      getContractUrl();
    });

    // 获取合同预览链接
    const getContractUrl = () => {
      state.dialogLoading = true;
      previewTemplateContract({ templateid: prop.previewData.templateid }).then(response => {
        if (response.code == 200) {
          state.url = response.data.viewUrl;
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.dialogLoading = false;
      })
    }

    // 发送合同
    const send = () => {
      state.isDisabled = true;
      state.dialogLoading = true;
      sendContract({
        agentid: prop.previewData.agentid,
        templateid: prop.previewData.templateid,
        txt_creator: store.getters.userInfo.name || "系统用户",
        dt_create: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      }).then(response => {
        if (response.code == 200) {
          ElMessage.success("发送成功");
          close();
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(e);
      }).finally(() => {
        state.isDisabled = false;
        state.dialogLoading = false;
      })
    }

    const close = () => {
      ctx.emit("close");
    }

    return {
      ...toRefs(state),
      close,
      getContractUrl,
      send
    }
  }
}
</script>

<style lang="scss">
.el-dialog {
  &.view-contract-dialog {
    margin-top: 8vh;
    height: calc(100% - 14vh);
  }
}

.my-iframe {
  height: 100%;

  iframe {
    height: 100%;
    width: 100%;
  }
}
</style>
