<template>
<el-row class="comp-statistical">
  <statistical-item :span="5" v-for="(item, index) in data" :key="index" :data="item" />
</el-row>
</template>

<script>
// 组件 - 顶部统计
import StatisticalItem from '../StatisticalItem/index.vue';
import { reactive, toRefs } from 'vue';
export default {
  props: {
    data: { type: Array },
  },
  components: { StatisticalItem },
  setup() {
    const state = reactive({});

    return toRefs(state);
  }

}
</script>

<style lang="scss" scoped>
.comp-statistical {
  height: 120px;
  margin: 2px 0 0 2px;
}
</style>
